import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import CardMedia from '@mui/material/CardMedia';

export const FinishAuthLoader = ({ isAnimationFinished, setAnimationFinished }) => {
  const [loaderDots, setLoaderDots] = useState([]);
  const storageAppLogo = sessionStorage.getItem('appLogo');

  useEffect(() => {
    if (!isAnimationFinished) {
      const updatedArr = [...loaderDots];

      if (loaderDots.length < 8) {
        const addDots = setTimeout(() => {
          updatedArr.push(`item-${loaderDots.length + 1}`);
          setLoaderDots(updatedArr);
        }, 500);

        return () => clearTimeout(addDots);
      }

      if (loaderDots.length === 8) {
        setAnimationFinished();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAnimationFinished, loaderDots]);

  return (
    <Grid
      container
      item
      justifyContent="space-between"
      alignItems="center"
      margin="150px auto"
      maxWidth={400}
    >
      <Grid className="bank-icon">
        <AccountBalanceRoundedIcon fontSize="large" />
      </Grid>

      <Grid
        container
        item
        alignItems="center"
        lg
        sx={{ backgroundColor: '#65B04B70', borderRadius: '10px' }}
        height="12px"
        maxWidth="128px"
      >
        {loaderDots.map((elIndex) => (
          <Grid
            key={elIndex}
            sx={{
              height: '8px',
              width: '8px',
              backgroundColor: '#65B04B',
              borderRadius: '50%',
              margin: '2px 4px',
            }}
          />
        ))}
      </Grid>

      <Grid className="bank-icon">
        <CardMedia src={storageAppLogo} component="img" sx={{ height: '22px' }} />
      </Grid>
    </Grid>
  );
};

FinishAuthLoader.propTypes = {
  isAnimationFinished: PropTypes.bool.isRequired,
  setAnimationFinished: PropTypes.func.isRequired,
};
