export const getFieldsDefaults = ({ main }) =>
  main.reduce((acc, field) => {
    if (field.type === 'checkbox') {
      acc[field.name] = field.checked;
      return acc;
    }

    if (field.defaultValue) {
      acc[field.name] = field.defaultValue;
    }

    if (!field.defaultValue && field.type !== 'label') {
      acc[field.name] = '';
    }

    return acc;
  }, {});

export const randomUUID = () => {
  if (window?.crypto?.randomUUID) {
    return crypto.randomUUID();
  }

  const randomHex = () => Math.floor(Math.random() * 16).toString(16);
  const getHexString = (length) =>
    Array(length)
      .fill('')
      .map(() => randomHex())
      .join('');
  const hexLength4 = () => getHexString(4);
  const hexLength8 = () => getHexString(8);
  const hexLength12 = () => getHexString(12);

  return `${hexLength8()}-${hexLength4()}-${hexLength4()}-${hexLength4()}-${hexLength12()}`;
};
