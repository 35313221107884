import React, { useContext, useEffect, useState } from 'react';
import { useLocation, Outlet, useNavigate } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import queryString from 'query-string';
import { Helmet, HelmetProvider } from 'react-helmet-async';
/* eslint-disable camelcase */
import jwt_decode from 'jwt-decode';

import { ThemeProvider } from '@mui/material/styles';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';
import { Header, Footer, ErrorPage } from './components';
import {
  TRANSLATES,
  DEFAULTS,
  keysToCamel,
  reverseString,
  omit,
  LANGUAGES,
  getCookie,
  setCookie,
  deleteCookie,
} from '../utils';
import { ConnectorContext } from '../context';
import { getConnectorData, checkIsValidState, getChosenApplication } from '../fetch/requests';
import useTrackingEvents from './hooks/useTrackingEvents';
import theme from '../theme';

const EXCLUDED_PARAMS = ['clientId', 'state', 'region', 'trackingId'];

export const AppLayout = () => {
  const {
    state: {
      clientId,
      clientName,
      isLoading,
      error,
      applications,
      currentApp,
      isValidState,
      triggeredEvents,
    },
    updateState,
  } = useContext(ConnectorContext);
  const { search, pathname } = useLocation();
  const navigate = useNavigate();

  const isSomeStep = pathname.includes('step');
  const isLastStep = pathname.includes('step-authorized');
  const params = keysToCamel(queryString.parse(search));
  const storageAppId = sessionStorage.getItem('appId');
  const decoded = params.clientId ? '' : jwt_decode(params.state);
  const applicationData = omit(EXCLUDED_PARAMS, params);

  const [selectedLanguage, setSelectedLanguage] = useState(DEFAULTS.LANG);

  const handleLanguageChange = (event) => {
    const newLanguage = event?.target?.value;
    updateState({ lang: newLanguage });
    deleteCookie('lang');
    setCookie('lang', newLanguage);
    setSelectedLanguage(newLanguage);
  };

  const parsedClientId = clientId || reverseString(decoded.c);

  useEffect(() => {
    if (storageAppId) {
      const currentApp = applications?.find((apps) => apps.id === parseInt(storageAppId, 10));

      if (currentApp) {
        updateState({ currentApp });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [applications]);

  useEffect(() => {
    if (isValidState && params.applicationId) {
      getChosenApplication({
        updateState,
        clientId: parsedClientId,
        applicationId: params.applicationId,
      });

      sessionStorage.setItem('appId', params.applicationId);
      sessionStorage.setItem('appLogo', applications && applications[0].applicationLogoUrl);
      updateState({ currentApp: applications && applications[0] });

      navigate(`/connect/step/1${search}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidState, params.applicationId]);

  useEffect(() => {
    if (parsedClientId) {
      checkIsValidState({
        updateState,
        clientId: parsedClientId,
        state: params.state,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [parsedClientId]);

  useEffect(() => {
    if (isValidState) {
      getConnectorData({ updateState, clientId: parsedClientId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidState]);

  useEffect(() => {
    if (!params) return;
    updateState(params);

    const language = params?.lang;
    if (language && Object.values(LANGUAGES).includes(language)) {
      setSelectedLanguage(language);
      deleteCookie('lang');
      setCookie('lang', language);
    }

    if (applicationData) {
      updateState({ applicationData });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(params)]);

  useEffect(() => {
    const languageFromCookies = getCookie('lang');
    if (languageFromCookies) {
      setSelectedLanguage(languageFromCookies);
    }
  }, []);

  // TODO: Future implementation: Update it using trackingId received from the query
  useTrackingEvents({ currentApp, triggeredEvents, updateState });

  const layout = () => (
    <Grid item>
      <Header selectedLanguage={selectedLanguage} handleLanguageChange={handleLanguageChange} />
      {error ? <ErrorPage /> : <Outlet />}
    </Grid>
  );

  const renderLayout = () => {
    if (!isLoading && !isValidState) {
      return <ErrorPage />;
    }

    if (isLastStep) {
      return layout();
    }

    if (isLoading || (storageAppId && !currentApp)) {
      return (
        <Grid container item justifyContent="center" padding="60px">
          <CircularProgress color="lightDark" />
        </Grid>
      );
    }

    if (isSomeStep && !storageAppId) {
      return navigate(`/connect${search}`);
    }

    return layout();
  };

  return (
    <ThemeProvider theme={theme}>
      <IntlProvider
        messages={TRANSLATES[selectedLanguage]}
        locale={selectedLanguage}
        defaultLocale={DEFAULTS.LANG}
        onError={() => {}}
      >
        <HelmetProvider>
          <Helmet>
            <title>{clientName || 'Connector UI'}</title>
          </Helmet>
          <Grid alignItems="center" height="100vh" container>
            <Grid
              container
              item
              className="root-grid"
              flexWrap="nowrap"
              justifyContent="space-between"
              direction="column"
              maxWidth={512}
            >
              {renderLayout()}

              <Grid item>
                <Footer />
              </Grid>
            </Grid>
          </Grid>
        </HelmetProvider>
      </IntlProvider>
    </ThemeProvider>
  );
};
