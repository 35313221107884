import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const FinishAuthBodyContent = () => {
  const [seconds, setSeconds] = useState(5);

  useEffect(() => {
    if (seconds > 0) {
      const timeoutId = setTimeout(() => setSeconds(seconds - 1), 1000);

      return () => {
        clearTimeout(timeoutId);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [seconds]);

  return (
    <Grid paddingBottom="30px" minHeight="100px" item xs>
      <Grid paddingBottom="30px" minHeight="100px" item xs>
        <Typography
          gutterBottom
          sx={{
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: 600,
            lineHeight: '20.43px',
            color: '#6C6C6C',
          }}
        >
          <FormattedMessage id="successful_connection_subtitle" values={{ seconds }} />
        </Typography>
        <Typography
          paddingTop="16px"
          component="p"
          sx={{
            textAlign: 'center',
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '20.43px',
            color: '#6C6C6C',
          }}
        >
          <FormattedMessage id="successful_connection_description" />
        </Typography>
      </Grid>
    </Grid>
  );
};
