import axios from 'axios';

export const getBaseUrl = () => {
  const { REACT_APP_ENVIRONMENT } = process.env;
  if (REACT_APP_ENVIRONMENT && REACT_APP_ENVIRONMENT === 'local') {
    return 'http://localhost:8080';
  }

  return window.location.origin;
};

const config = {
  baseURL: getBaseUrl(),
  method: 'GET',
  dataType: 'JSON',
  contentType: 'application/json',
};

export const request = axios.create(config);
