export const startAuthLoaderAnimation = () => {
  const intervalAnimation = setInterval(() => {
    const loadEls = document.getElementsByClassName('animated');
    [...loadEls].forEach((el) => {
      if (el.className.includes('small')) {
        el.classList.replace('small', 'big');
      } else {
        el.classList.replace('big', 'small');
      }
    });
  }, 500);

  return () => {
    clearInterval(intervalAnimation);
  };
};
