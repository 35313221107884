/* eslint-disable radix */
/* eslint-disable operator-linebreak */
import React, { useContext, useState, useMemo, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { format as dateFnsFormat } from 'date-fns';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextLabel from '../components/FormComponents/TextLabel';
import Input from '../components/FormComponents/Input';
import Select from '../components/FormComponents/Select';
import Radiobutton from '../components/FormComponents/Radiobutton';
import Checkbox from '../components/FormComponents/Checkbox';
import DateCalendar from '../components/FormComponents/DateCalendar/DateCalendar';
import InputDate from '../components/FormComponents/InputDate';
import { ConnectorContext } from '../../context';
import { BTN_ACTIONS, getFieldsDefaults } from '../../utils';

export const VerificationBeforeConnect = () => {
  const {
    state: {
      currentApp: { steps },
      applicationData,
    },
    updateState,
  } = useContext(ConnectorContext);
  const navigate = useNavigate();
  const { search } = useLocation();
  const { id } = useParams();
  const stepsLength = Object.keys(steps).length;

  const fieldBlocks = useMemo(
    () =>
      steps[id].reduce(
        (acc, field) => {
          if (field.type === 'button') {
            acc.buttons.push(field);
          } else {
            acc.main.push(field);
          }

          return acc;
        },
        { main: [], buttons: [] }
      ),
    [id]
  );

  const queryParams = new URLSearchParams(useLocation().search);

  const [formValues, setFormValues] = useState(getFieldsDefaults(fieldBlocks));
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const setQueryParamAsDefaultValue = (fieldName, fieldValue) => {
      const fieldExists = fieldBlocks.main.some((field) => field.name === fieldName);
      if (fieldExists) {
        setFormValues((prevState) => ({ ...prevState, [fieldName]: fieldValue }));
      }
    };

    queryParams.forEach((value, name) => {
      setQueryParamAsDefaultValue(name, value);
    });

    const hasRequiredCalendar = fieldBlocks.main.some(
      (field) => field.required && field.type === 'calendar'
    );

    const isCalendarValueEntered = fieldBlocks.main.some(
      (field) => field.type === 'calendar' && formValues[field.name]
    );

    setIsButtonDisabled(
      hasRequiredCalendar && !queryParams.has('invoice_start_date') && !isCalendarValueEntered
    );
  }, [id]);

  const onChange = ({ target: { name, value } }) => {
    setFormValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const onCheckboxChange = ({ target: { name, checked } }) => {
    setFormValues((prevState) => ({ ...prevState, [name]: checked }));
  };

  const formatDateForAPI = (date) => dateFnsFormat(date, 'yyyy-MM-dd');

  const onCalendarChange = (value, name) => {
    const formattedDate = formatDateForAPI(value);
    setFormValues((prevState) => ({ ...prevState, [name]: formattedDate }));
    setIsButtonDisabled(
      !queryParams.has('invoice_start_date') &&
        fieldBlocks.main.some((field) => field.required && field.type === 'calendar') &&
        !formattedDate
    );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await updateState({ applicationData: { ...applicationData, ...formValues } });

    if (parseInt(id) + 1 === stepsLength) {
      await navigate(`/connect/last-step${search}`);
    } else {
      await navigate(`/connect/step/${parseInt(id) + 1}${search}`);
    }
  };

  const renderButtons = () =>
    fieldBlocks.buttons.map((btn) => {
      if (btn.action === BTN_ACTIONS.NEXT_STEP) {
        return (
          <Button
            key={btn.index}
            variant="contained"
            color="blackBtn"
            type="submit"
            sx={{ marginTop: '10px', color: '#fff' }}
            fullWidth
            disabled={isButtonDisabled}
            id={btn?.name || 'next_btn'}
            data-testid={btn?.name || 'next_btn'}
          >
            <FormattedMessage id={btn.displayName} />
          </Button>
        );
      }

      if (btn.action === BTN_ACTIONS.BEACON) {
        return (
          <Button
            key={btn.index}
            variant="outlined"
            sx={{ marginTop: '10px' }}
            data-beacon-article-sidebar={btn.articleId}
            href="#"
            fullWidth
          >
            <FormattedMessage id={btn.displayName} />
          </Button>
        );
      }

      return (
        <Button
          key={btn.index}
          variant="outlined"
          href={btn.url}
          sx={{ marginTop: '10px' }}
          fullWidth
        >
          <FormattedMessage id={btn.displayName} />
        </Button>
      );
    });

  const getFields = (field) => ({
    label: (
      <TextLabel key={field.index} field={field} dataTestid={`${field.type}_${field.index}`} />
    ),
    input: (
      <Input
        key={field.index}
        field={field}
        formValues={formValues}
        onChange={onChange}
        dataTestid={`${field.type}_${field.index}`}
      />
    ),
    input_date: (
      <InputDate
        key={field.index}
        field={field}
        formValues={formValues}
        onChange={onChange}
        format={field.date_format}
        dataTestid={`${field.type}_${field.index}`}
      />
    ),
    select: (
      <Select
        key={field.index}
        field={field}
        formValues={formValues}
        onChange={onChange}
        dataTestid={`${field.type}_${field.index}`}
      />
    ),
    radiobutton: (
      <Radiobutton
        key={field.index}
        field={field}
        formValues={formValues}
        onChange={onChange}
        dataTestid={`${field.type}_${field.index}`}
      />
    ),
    checkbox: (
      <Checkbox
        key={field.index}
        field={field}
        formValues={formValues}
        onChange={onCheckboxChange}
        dataTestid={`${field.type}_${field.index}`}
      />
    ),
    calendar: (
      <DateCalendar
        key={field.index}
        field={field}
        formValues={formValues}
        onChange={(value) => onCalendarChange(value, field.name)}
        dataTestid={`${field.type}_${field.index}`}
      />
    ),
  });

  return (
    <Grid container item justifyContent="center" direction="column" margin="0 auto">
      <Grid paddingBottom="30px" item xs>
        <Grid item>
          <Typography data-testid="connection_step_title">
            <FormattedMessage id="connection_step_title" />
          </Typography>
        </Grid>

        <form onSubmit={handleSubmit}>
          <Grid item padding="40px 0">
            {fieldBlocks.main.map((field) => getFields(field)[field.type])}
          </Grid>

          <Grid item margin="0 auto" maxWidth="400px">
            {renderButtons()}
          </Grid>
        </form>
      </Grid>
    </Grid>
  );
};
