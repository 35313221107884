import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { ConnectorContext } from '../../../context';

export const StartAuthTitleContent = ({ isStartedConnection }) => {
  const [seconds, setSeconds] = useState(3);
  const {
    state: {
      currentApp: { name },
    },
  } = useContext(ConnectorContext);

  const startCountdown = () => {
    const countdown = setTimeout(() => setSeconds(seconds - 1), 1000);

    return () => {
      clearTimeout(countdown);
    };
  };

  useEffect(() => {
    if (isStartedConnection && seconds > 0) {
      startCountdown();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isStartedConnection, seconds]);

  return (
    <Grid paddingBottom="30px" minHeight="100px" item xs>
      <Typography gutterBottom>
        <FormattedMessage
          id={isStartedConnection ? 'connection_title' : 'pre_connection_title'}
          values={{ appName: name }}
        />
      </Typography>
      <Typography variant="subtitle">
        <FormattedMessage
          id={isStartedConnection ? 'connection_subtitle' : 'pre_connection_subtitle'}
          values={{ appName: name, seconds }}
        />
      </Typography>
    </Grid>
  );
};

StartAuthTitleContent.propTypes = {
  isStartedConnection: PropTypes.bool.isRequired,
};
