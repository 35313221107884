import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

// eslint-disable-next-line no-unused-vars
const InputDate = ({ field, formValues, onChange, dataTestid, format }) => {
  const intl = useIntl();
  const [inputValue, setInputValue] = useState('');
  const [inputError, setInputError] = useState('');

  const handleChange = (event) => {
    const { value } = event.target;
    const defaultFormat = 'YYYY-MM-DD';
    const formats = {
      'YYYY-MM-DD': /^\d{4}-\d{2}-\d{2}$/,
    };
    const validDateRegex = formats[format] || formats[defaultFormat];

    let formattedValidDate = '';
    const numericValue = value.replace(/\D/g, '');

    if (numericValue.length >= 4) {
      const year = numericValue.slice(0, 4);
      const month = numericValue.slice(4, 6);
      const day = numericValue.slice(6, 8);

      let formattedDate = year;
      if (month) formattedDate += `-${month}`;
      if (day) formattedDate += `-${day}`;

      setInputValue(formattedDate);
      formattedValidDate = formattedDate;
    } else {
      setInputValue(numericValue);
    }

    if (validDateRegex.test(formattedValidDate)) {
      setInputError('');
      // eslint-disable-next-line no-param-reassign
      event.target.value = formattedValidDate;
      onChange(event);
    } else {
      setInputError(`Input valid date format: ${format}`);
    }
  };

  return (
    <Grid container item key={field.index} alignItems="center" marginY="20px">
      <TextField
        type="tel"
        fullWidth
        size="small"
        required={field.required}
        id={field.name}
        label={intl.formatMessage({ id: field.displayName })}
        name={field.name}
        placeholder={field.placeholder}
        value={inputValue}
        onChange={handleChange}
        data-testid={dataTestid}
        inputProps={{ 'data-testid': dataTestid }}
        error={!!inputError}
        helperText={inputError}
      />
    </Grid>
  );
};

InputDate.defaultProps = {
  dataTestid: 'data_testid',
  format: 'YYYY-MM-DD',
};

InputDate.propTypes = {
  field: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
  format: PropTypes.string,
};

export default InputDate;
