import { useEffect } from 'react';
import { getBaseUrl } from '../../fetch/axiosConfig';

const useSendTrackingEvents = ({
  isStartSendingEvents,
  triggeredEvents,
  trackingId,
  clientId,
  setIsEventsSent,
}) => {
  useEffect(() => {
    const sendEvents = async () => {
      try {
        const eventsRequests = triggeredEvents.map(async (event) => {
          const eventPostResponse = await fetch(`${getBaseUrl()}/api/connector/tracking-event`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CLIENT-ID': clientId,
              'X-TRACKING-ID': trackingId,
            },
            body: JSON.stringify(event),
          });
          if (!eventPostResponse.ok) {
            console.error('Error while making event send post request');
          }

          return eventPostResponse.json();
        });

        await Promise.allSettled(eventsRequests);

        setIsEventsSent(true);
      } catch (error) {
        console.error(`sendEvents error: ${error}`);
        setIsEventsSent(true);
      }
    };

    if (isStartSendingEvents) {
      sendEvents();
    }
  }, [isStartSendingEvents, triggeredEvents, trackingId]);
};

export default useSendTrackingEvents;
