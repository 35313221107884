import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';

const Select = ({ field, formValues, onChange, dataTestid }) => {
  const intl = useIntl();

  return (
    <Grid container item key={field.index} alignItems="center" marginY="20px">
      <TextField
        fullWidth
        select
        size="small"
        required={field.required}
        id={field.name}
        label={intl.formatMessage({ id: field.displayName })}
        name={field.name}
        value={formValues[field.name]}
        onChange={onChange}
        inputProps={{ 'data-testid': dataTestid }}
      >
        {field.options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    </Grid>
  );
};

Select.defaultProps = {
  dataTestid: false,
};

Select.propTypes = {
  field: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string || PropTypes.bool,
};

export default Select;
