import React from 'react';

const English = () => (
  <div style={{ transform: 'scale(0.5)' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="48px"
      height="48px"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          style={{ opacity: 0.955 }}
          fill="#4152b5"
          d="M 18.5,3.5 C 18.6657,7.84611 18.499,12.1794 18,16.5C 14.6894,14.0969 11.1894,12.0969 7.5,10.5C 10.3608,6.98586 14.0275,4.65253 18.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.933 }}
          fill="#fcfafb"
          d="M 18.5,3.5 C 19.1667,3.5 19.8333,3.5 20.5,3.5C 20.5,9.16667 20.5,14.8333 20.5,20.5C 14.8333,20.5 9.16667,20.5 3.5,20.5C 3.23219,18.901 3.56552,17.5676 4.5,16.5C 14.8149,19.4933 15.1482,18.4933 5.5,13.5C 6.16667,12.5 6.83333,11.5 7.5,10.5C 11.1894,12.0969 14.6894,14.0969 18,16.5C 18.499,12.1794 18.6657,7.84611 18.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.996 }}
          fill="#e43834"
          d="M 20.5,3.5 C 22.5,3.5 24.5,3.5 26.5,3.5C 26.5,9.16667 26.5,14.8333 26.5,20.5C 32.1667,20.5 37.8333,20.5 43.5,20.5C 43.5,22.5 43.5,24.5 43.5,26.5C 37.8333,26.5 32.1667,26.5 26.5,26.5C 26.5,32.1667 26.5,37.8333 26.5,43.5C 24.5,43.5 22.5,43.5 20.5,43.5C 20.5,37.8333 20.5,32.1667 20.5,26.5C 14.8333,26.5 9.16667,26.5 3.5,26.5C 3.5,24.5 3.5,22.5 3.5,20.5C 9.16667,20.5 14.8333,20.5 20.5,20.5C 20.5,14.8333 20.5,9.16667 20.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.927 }}
          fill="#fbf9fb"
          d="M 26.5,3.5 C 27.1667,3.5 27.8333,3.5 28.5,3.5C 28.1744,7.88387 28.5078,12.2172 29.5,16.5C 32.8611,14.4887 36.1944,12.4887 39.5,10.5C 39.8333,11.1667 40.1667,11.8333 40.5,12.5C 37.0568,14.1044 33.7234,15.9377 30.5,18C 34.9589,19.0939 38.6256,17.9273 41.5,14.5C 42.7384,16.2143 43.405,18.2143 43.5,20.5C 37.8333,20.5 32.1667,20.5 26.5,20.5C 26.5,14.8333 26.5,9.16667 26.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.963 }}
          fill="#4152b5"
          d="M 28.5,3.5 C 32.9725,4.65253 36.6392,6.98586 39.5,10.5C 36.1944,12.4887 32.8611,14.4887 29.5,16.5C 28.5078,12.2172 28.1744,7.88387 28.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.985 }}
          fill="#e64640"
          d="M 40.5,12.5 C 41.3382,12.8417 41.6716,13.5084 41.5,14.5C 38.6256,17.9273 34.9589,19.0939 30.5,18C 33.7234,15.9377 37.0568,14.1044 40.5,12.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.947 }}
          fill="#e6433e"
          d="M 5.5,13.5 C 15.1482,18.4933 14.8149,19.4933 4.5,16.5C 4.55688,15.391 4.89022,14.391 5.5,13.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.934 }}
          fill="#fcfafb"
          d="M 3.5,26.5 C 9.16667,26.5 14.8333,26.5 20.5,26.5C 20.5,32.1667 20.5,37.8333 20.5,43.5C 19.8333,43.5 19.1667,43.5 18.5,43.5C 18.8256,39.1161 18.4922,34.7828 17.5,30.5C 14.4114,32.9249 11.0781,34.9249 7.5,36.5C 7.5,36.1667 7.5,35.8333 7.5,35.5C 10.079,32.8652 13.079,30.6985 16.5,29C 14.5,28.3333 12.5,28.3333 10.5,29C 8.80428,30.1764 7.13761,31.343 5.5,32.5C 4.26162,30.7857 3.59495,28.7857 3.5,26.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.937 }}
          fill="#fcfafb"
          d="M 43.5,26.5 C 43.7678,28.099 43.4345,29.4324 42.5,30.5C 40.5449,28.6742 38.2116,28.1742 35.5,29C 37.3911,30.4424 39.3911,31.6091 41.5,32.5C 41.573,34.0269 40.9063,35.0269 39.5,35.5C 35.8106,33.9031 32.3106,31.9031 29,29.5C 28.5008,34.1548 28.3342,38.8215 28.5,43.5C 27.8333,43.5 27.1667,43.5 26.5,43.5C 26.5,37.8333 26.5,32.1667 26.5,26.5C 32.1667,26.5 37.8333,26.5 43.5,26.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.963 }}
          fill="#e6453f"
          d="M 7.5,35.5 C 6.09368,35.0269 5.42702,34.0269 5.5,32.5C 7.13761,31.343 8.80428,30.1764 10.5,29C 12.5,28.3333 14.5,28.3333 16.5,29C 13.079,30.6985 10.079,32.8652 7.5,35.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.933 }}
          fill="#e6443e"
          d="M 42.5,30.5 C 42.6716,31.4916 42.3382,32.1583 41.5,32.5C 39.3911,31.6091 37.3911,30.4424 35.5,29C 38.2116,28.1742 40.5449,28.6742 42.5,30.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.954 }}
          fill="#4252b6"
          d="M 18.5,43.5 C 14.0275,42.3475 10.3608,40.0141 7.5,36.5C 11.0781,34.9249 14.4114,32.9249 17.5,30.5C 18.4922,34.7828 18.8256,39.1161 18.5,43.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.98 }}
          fill="#4153b4"
          d="M 39.5,35.5 C 37.0247,39.6203 33.358,42.2869 28.5,43.5C 28.3342,38.8215 28.5008,34.1548 29,29.5C 32.3106,31.9031 35.8106,33.9031 39.5,35.5 Z"
        />
      </g>
    </svg>
  </div>
);

export default English;
