import translatesEn from '../locales/en.json';
import translatesSv from '../locales/sv.json';
import translatesFi from '../locales/fi.json';

export const TRANSLATES = {
  en: translatesEn,
  sv: translatesSv,
  fi: translatesFi,
};

export const DEFAULTS = {
  LANG: 'sv',
};

export const LANGUAGES = {
  SV: 'sv',
  NO: 'no',
  EN: 'en',
  DA: 'da',
  FI: 'fi',
};

export const BTN_ACTIONS = {
  NEXT_STEP: 'NEXT_STEP',
  BEACON: 'BEACON',
  REDIRECT: 'REDIRECT',
};
