/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { ConnectorContext } from '../../context';

export const Footer = () => {
  const {
    state: { returnUri, error, currentApp },
  } = useContext(ConnectorContext);
  const { pathname } = useLocation();

  const stepName = pathname.replace('/connect', '');
  const isInitialPath = stepName === '';
  const { id } = useParams();

  let shouldDisplayCancelButton = false;
  if (currentApp?.steps) {
    const stepsLength = Object.keys(currentApp.steps).length;
    shouldDisplayCancelButton = id >= 1 && id < stepsLength;
  }

  return (
    <Grid container item justifyContent="center" direction="column" padding="10px 0 30px">
      {!error && (isInitialPath || shouldDisplayCancelButton) ? (
        <Grid item xs textAlign="center">
          <Button color="cancelBtnGrey" href={returnUri} data-testid="cancel_btn">
            <FormattedMessage id="cancel_btn" />
          </Button>
        </Grid>
      ) : null}
      <Grid item container justifyContent="center" alignItems="center" xs>
        <Typography variant="footer" data-testid="footer_message">
          <FormattedMessage id="footer_message" />
        </Typography>
        <a
          className="footer-link"
          href="https://strawbay.io/"
          target="_blank"
          rel="noreferrer"
          data-testid="strawbay_link"
        >
          <img height="14px" width="70px" src="/img/strawbay-logo.png" alt="strawbay.io" />
        </a>
      </Grid>
    </Grid>
  );
};
