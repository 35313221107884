import React from 'react';
import PropTypes from 'prop-types';
import { MenuItem, Select, Typography } from '@mui/material';
import Svenska from '../../assets/icons/Svenska';
import Norsk from '../../assets/icons/Norsk';
import English from '../../assets/icons/English';
import Dansk from '../../assets/icons/Dansk';
import Suomi from '../../assets/icons/Suomi';

const LanguageSelector = ({ selectedLanguage, handleLanguageChange }) => (
  <div
    style={{ position: 'absolute', top: '-52px', right: '-10px', zIndex: 1111 }}
    data-testid="language_selector_wrapper"
  >
    <Select
      role="combobox"
      inputProps={{ 'data-testid': 'language_selector_select' }}
      value={selectedLanguage}
      onChange={handleLanguageChange}
      className="language-selector"
      sx={{
        boxShadow: 'none',
        '.MuiOutlinedInput-notchedOutline': { border: 0 },
        '&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },
        '&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          border: 0,
        },

        '& .input.MuiSelect-select': {
          paddingRight: '16px',
        },
        '& .MuiSelect-select': {
          padding: '0px 0px',
        },
      }}
    >
      <MenuItem
        value="sv"
        role="menuitem"
        className="language-menu-item"
        data-testid="language_selector_option_sv"
      >
        <Svenska />
        <Typography variant="body1">Svenska</Typography>
      </MenuItem>
      <MenuItem
        value="no"
        role="menuitem"
        className="language-menu-item"
        data-testid="language_selector_option_no"
      >
        <Norsk />
        <Typography variant="body1">Norsk</Typography>
      </MenuItem>
      <MenuItem
        value="en"
        role="menuitem"
        className="language-menu-item"
        data-testid="language_selector_option_en"
      >
        <English />
        <Typography variant="body1">English</Typography>
      </MenuItem>
      <MenuItem
        value="da"
        role="menuitem"
        className="language-menu-item"
        data-testid="language_selector_option_da"
      >
        <Dansk />
        <Typography variant="body1">Dansk</Typography>
      </MenuItem>
      <MenuItem
        value="fi"
        role="menuitem"
        className="language-menu-item"
        data-testid="language_selector_option_fi"
      >
        <Suomi />
        <Typography variant="body1">Suomi</Typography>
      </MenuItem>
    </Select>
  </div>
);

LanguageSelector.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
};

export default LanguageSelector;
