import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import DoneIcon from '@mui/icons-material/Done';

const TextLabel = ({ field, dataTestid }) => (
  <Grid container item key={field.index} alignItems="center" paddingBottom="10px">
    <DoneIcon color="iconGrey" sx={{ fontSize: 16, marginRight: '15px' }} />
    <Typography variant="normal" sx={{ flex: 1 }} data-testid={dataTestid}>
      <FormattedMessage id={field.placeholder} />
    </Typography>
  </Grid>
);

TextLabel.defaultProps = {
  dataTestid: 'data_testid',
};

TextLabel.propTypes = {
  field: PropTypes.object.isRequired,
  dataTestid: PropTypes.string,
};

export default TextLabel;
