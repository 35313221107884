import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import CardMedia from '@mui/material/CardMedia';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { ConnectorContext } from '../../context';
import { InfoAlert } from '../components';
import { spacesToUnderscore } from '../../utils';

export const SelectAccountingSoftware = () => {
  const {
    state: { applications },
    updateState,
  } = useContext(ConnectorContext);
  const { search } = useLocation();

  const onSetChosenApp = (item) => {
    sessionStorage.setItem('appId', item.id);
    sessionStorage.setItem('appLogo', item.applicationLogoUrl);
    updateState({ currentApp: item });
  };

  const renderList = () => {
    if (applications.length === 0) {
      return <InfoAlert />;
    }

    return (
      <Grid item xs>
        <List>
          {applications.map((item) => (
            <Link
              key={item.id}
              to={`step/1${search}`}
              component={RouterLink}
              sx={{ textDecoration: 'none' }}
              data-testid={spacesToUnderscore(item.name)}
            >
              <ListItem
                secondaryAction={<ArrowForwardIosIcon color="iconGrey" sx={{ fontSize: 16 }} />}
                disablePadding
                divider
              >
                <ListItemButton onClick={() => onSetChosenApp(item)} sx={{ padding: '20px 0' }}>
                  {item.applicationLogoUrl ? (
                    <CardMedia
                      src={item.applicationLogoUrl}
                      component="img"
                      alt={item?.name}
                      sx={{ height: '22px', marginLeft: '15px' }}
                    />
                  ) : (
                    <ListItemText primary={item.name} />
                  )}
                </ListItemButton>
              </ListItem>
            </Link>
          ))}
        </List>
      </Grid>
    );
  };

  return (
    <Grid container item justifyContent="center" direction="column" margin="0 auto">
      <Grid paddingBottom="30px" item xs>
        <Typography gutterBottom>
          <FormattedMessage id="select_application" />
        </Typography>
        <Typography variant="subtitle">
          <FormattedMessage id="select_application_subtitle" />
        </Typography>
      </Grid>

      {renderList()}
    </Grid>
  );
};
