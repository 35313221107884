import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { defineElement } from '@lordicon/element';
import lottie from 'lottie-web';
import { FinishAuthTitleContent } from './FinishAuthTitleContent';
import { FinishAuthBodyContent } from './FinishAuthBodyContent';
import { ConnectorContext } from '../../../context';
import useTrackingEvents from '../../hooks/useTrackingEvents';
import { getBaseUrl } from '../../../fetch/axiosConfig';

const TIMEOUT_DELAY = 5000;

export const SuccessConnection = () => {
  const {
    state: {
      afterConnection,
      currentApp,
      triggeredEvents,
      trackingId,
      clientId,
      preStartConnection,
    },
    updateState,
  } = useContext(ConnectorContext);
  const [timeoutId, setTimeoutId] = useState(null);
  const storedTrackingId = sessionStorage.getItem('trackingId') || '';

  defineElement(lottie.loadAnimation);

  const redirectToNextStep = () => {
    if (afterConnection?.nextStep === 'REDIRECT') {
      const id = setTimeout(() => {
        window.location.href = afterConnection?.redirectUrl;
      }, TIMEOUT_DELAY);

      setTimeoutId(id);
    }
  };

  useEffect(() => {
    redirectToNextStep();

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, []);

  useTrackingEvents({ currentApp, triggeredEvents, clientId, trackingId, updateState });

  useEffect(() => {
    if (triggeredEvents?.length) {
      const sendEvent = async () => {
        try {
          const eventData = triggeredEvents[triggeredEvents.length - 1];
          const eventPostResponse = await fetch(`${getBaseUrl()}/api/connector/tracking-event`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'X-CLIENT-ID': clientId,
              'X-TRACKING-ID': preStartConnection?.trackingId || trackingId || storedTrackingId,
            },
            body: JSON.stringify(eventData),
          });
          if (!eventPostResponse.ok) {
            console.error('Error while making event send post request');
          }

          sessionStorage.setItem('trackingId', '');
          return eventPostResponse.json();
        } catch (error) {
          sessionStorage.setItem('trackingId', '');
          console.error(`sendEvent error: ${error}`);
        }
      };
      sendEvent();
    }
  }, [triggeredEvents]);

  const onReturnHandle = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }

    window.location.href = afterConnection?.redirectUrl;
  };

  return (
    <Grid container item justifyContent="center" direction="column" margin="0 auto">
      <FinishAuthTitleContent />

      <Grid item sx={{ margin: '0 auto 40px' }}>
        <lord-icon
          role="img"
          trigger="in"
          style={{ width: '76px', height: '76px' }}
          src="https://cdn.lordicon.com/wrinvdzx.json"
        />
      </Grid>

      <FinishAuthBodyContent />

      <Grid item paddingTop="36px">
        <Button
          variant="contained"
          sx={{ marginTop: '10px', color: '#fff', backgroundColor: '#25213D' }}
          color="blackBtn"
          onClick={onReturnHandle}
          fullWidth
          data-testid="redirect_btn"
        >
          <FormattedMessage id="close_btn" />
        </Button>
      </Grid>
    </Grid>
  );
};
