import { createContext } from 'react';

export const ConnectorContext = createContext({
  state: {
    applications: [],
    error: false,
    triggeredEvents: [],
  },
  updateState: () => {},
});
