import { request } from './axiosConfig';
import { keysToCamel } from '../utils';

const baseUrl = 'api/connector';

export const checkIsValidState = ({ updateState, clientId, state }) =>
  request
    .get(`${baseUrl}/connector/client/${clientId}/state/${state}`)
    .then(() => {
      updateState({ error: false, isValidState: true, isLoading: true });
    })
    .catch(({ response }) => {
      updateState({
        errorAction: response?.data?.action,
        returnUri: response?.data?.url,
        error: true,
        isValidState: false,
        isLoading: false,
      });
    });

export const getConnectorData = ({ updateState, clientId }) =>
  request
    .get(`${baseUrl}/connector/client/${clientId}`)
    .then((response) => {
      sessionStorage.setItem('clientLogo', response.data.client_logo_url);
      updateState({
        ...keysToCamel(response.data),
        isLoading: false,
      });
    })
    .catch(({ response }) => {
      updateState({
        errorAction: response?.data?.action,
        returnUri: response?.data?.url,
        isLoading: false,
        error: true,
      });
    });

export const getAuthorizeUrl = ({ updateState, requestBody }) =>
  request
    .post(`${baseUrl}/connection`, requestBody)
    .then((response) => {
      updateState({
        preStartConnection: {
          ...keysToCamel(response.data),
          isReadyToConnect: true,
        },
        error: false,
      });
    })
    .catch(({ response }) => {
      updateState({
        preStartConnection: {
          isReadyToConnect: false,
        },
        error: true,
        errorAction: response?.data?.action,
        returnUri: response?.data?.url,
        errorMessage: response?.data?.error_message,
      });
    });

export const setConnectionData = ({ updateState, requestBody }) =>
  request
    .post(`${baseUrl}/auth/obtain-access-token/`, requestBody)
    .then((response) => {
      updateState({
        afterConnection: {
          ...keysToCamel(response.data),
        },
        error: false,
      });
    })
    .catch(({ response }) => {
      updateState({
        afterConnection: {},
        error: true,
        errorAction: response?.data?.action,
        returnUri: response?.data?.url,
        errorMessage: response?.data?.error_message,
      });
    });

export const getChosenApplication = ({ updateState, clientId, applicationId }) =>
  request
    .get(`${baseUrl}/connector/client/${clientId}/${applicationId}`)
    .then((response) => {
      sessionStorage.setItem('clientLogo', response.data.client_logo_url);
      updateState({
        ...keysToCamel(response.data),
        isLoading: false,
      });
    })
    .catch(({ response }) => {
      updateState({
        errorAction: response?.data?.action,
        returnUri: response?.data?.url,
        isLoading: false,
        error: true,
      });
    });

// TODO: Future implementation: Receive trackingId and app data on /step-authorized
export const getTrackingInformation = () => {};

export const setTrackingInformation = ({ requestBody, headers = {} }) => {
  request
    .post(`${baseUrl}/tracking-event`, requestBody, {
      headers: {
        'X-CLIENT-ID': headers.clientId,
        'X-TRACKING-ID': headers.trackingId,
      },
    })
    .then(() => {})
    .catch(({ response }) => {
      console.error(response?.data?.error_message);
    });
};

export const setTrackingInformationByBeacon = ({ requestBody }) => {
  request
    .post(`${baseUrl}/tracking-event`, requestBody)
    .then(() => {})
    .catch(({ response }) => {
      console.error(response?.data?.error_message);
    });
};
