export class TrackingEvent {
  constructor({ eventType, currentStep }) {
    this.event_type = eventType || 'EVENT_TYPE';
    this.event_payload = {
      STEP: currentStep || 'STEP',
    };
  }
}

export const EVENT_ACTIONS = {
  CLICK: 'CLICK',
  ON_PAGE_LOAD: 'ON_PAGE_LOAD',
  ON_USER_REDIRECT: 'ON_USER_REDIRECT',
};

export const EVENT_TYPES = {
  WINDOW_CLOSED: 'WINDOW_CLOSED',
  USER_PERFORMED_REDIRECT: 'USER_PERFORMED_REDIRECT',
};

export const EVENT_KEYS = {
  STEP_OBTAIN_TOKEN: 'stepObtainToken',
};

export const STEPS = {
  LAST_STEP: 'last-step',
  STEP_AUTHORIZED: 'step-authorized',
};

export const determineStep = (path = '') => {
  const pathArray = path?.split('/');
  const step = pathArray[pathArray.length - 1] || 'step';
  return step;
};
