import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import AccountBalanceRoundedIcon from '@mui/icons-material/AccountBalanceRounded';
import CardMedia from '@mui/material/CardMedia';
import { startAuthLoaderAnimation } from '../../../utils';
import { ConnectorContext } from '../../../context';

export const StartAuthLoader = ({ isStartedConnection }) => {
  const {
    state: {
      currentApp: { applicationLogoUrl },
    },
  } = useContext(ConnectorContext);

  useEffect(() => {
    if (isStartedConnection) {
      startAuthLoaderAnimation();
    }
  }, [isStartedConnection]);

  return (
    <Grid
      container
      item
      justifyContent="space-between"
      alignItems="center"
      margin="50px auto"
      maxWidth={400}
    >
      <Grid className="bank-icon">
        <AccountBalanceRoundedIcon fontSize="large" />
      </Grid>

      <Grid container item justifyContent="center" alignItems="center" xs>
        <Grid className={isStartedConnection ? 'animated small' : 'pre-load'} />
        <Grid className={isStartedConnection ? 'animated big' : 'pre-load'} />
        <Grid className={isStartedConnection ? 'animated small' : 'pre-load'} />
        <Grid className={isStartedConnection ? 'animated big' : 'pre-load'} />
        <Grid className={isStartedConnection ? 'animated small' : 'pre-load'} />
        <Grid className={isStartedConnection ? 'animated big' : 'pre-load'} />
        <Grid className={isStartedConnection ? 'animated small' : 'pre-load'} />
      </Grid>

      <Grid className="bank-icon">
        <CardMedia src={applicationLogoUrl} component="img" sx={{ height: '22px' }} />
      </Grid>
    </Grid>
  );
};

StartAuthLoader.propTypes = {
  isStartedConnection: PropTypes.bool.isRequired,
};
