import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ConnectorContext } from '../context';
import { DEFAULTS } from '../utils';

const storageClientLogo = sessionStorage.getItem('clientLogo');

const initialState = {
  region: DEFAULTS.LANG,
  isLoading: true,
  error: false,
  clientLogoUrl: storageClientLogo,
  preStartConnection: {
    isReadyToConnect: false,
  },
  triggeredEvents: [],
};

export const WithConnectorContext = ({ children }) => {
  const [state, setState] = useState(initialState);

  const setConnectorState = (updatedState) => {
    setState((prevState) => ({ ...prevState, ...updatedState }));
  };

  const context = useMemo(
    () => ({
      state,
      updateState: setConnectorState,
    }), // eslint-disable-next-line react-hooks/exhaustive-deps
    [JSON.stringify(state)]
  );

  return <ConnectorContext.Provider value={context}>{children}</ConnectorContext.Provider>;
};

WithConnectorContext.propTypes = {
  children: PropTypes.element.isRequired,
};
