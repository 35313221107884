import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import {
  AppLayout,
  SelectAccountingSoftware,
  WithConnectorContext,
  VerificationBeforeConnect,
  StartAuthorize,
  FinishAuthorize,
} from './views';

export const AppRouter = () => (
  <WithConnectorContext>
    <BrowserRouter>
      <Routes>
        <Route path="/connect" element={<AppLayout />}>
          <Route path="" element={<SelectAccountingSoftware />} />
          <Route path="step/:id" element={<VerificationBeforeConnect />} />
          <Route path="last-step" element={<StartAuthorize />} />
          <Route path="step-authorized" element={<FinishAuthorize />} />
        </Route>
      </Routes>
    </BrowserRouter>
  </WithConnectorContext>
);
