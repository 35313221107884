/* eslint-disable camelcase */
import React, { useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import queryString from 'query-string';
import { Typography, Grid, Alert, Button } from '@mui/material';
import { ConnectorContext } from '../../context';

const REDIRECT = 'REDIRECT';

export const ErrorPage = ({ errorMsgId, padding }) => {
  const {
    state: { returnUri, errorMessage, errorAction },
  } = useContext(ConnectorContext);

  const { search } = useLocation();
  const params = queryString.parse(search);
  const { error, error_description } = params;

  useEffect(() => {
    if (errorAction === REDIRECT) {
      setTimeout(() => {
        window.location.href = returnUri;
      }, 3000);
    }
  }, [errorAction, returnUri]);

  return (
    <Grid container item justifyContent="center" padding={padding || '60px 0'}>
      {error ? (
        <Typography variant="body1" mb={2}>
          <FormattedMessage id={`Error: ${error}` || 'general_error_msg'} />
        </Typography>
      ) : null}
      <Alert severity="error">
        {!error || !error_description ? (
          <FormattedMessage id={errorMessage || errorMsgId || 'general_error_msg'} />
        ) : null}
        {error_description ? (
          <FormattedMessage
            id={`User does not have permission to onboard. Description: ${error_description}`}
          />
        ) : null}
      </Alert>
      {errorAction === REDIRECT ? null : (
        <Button variant="contained" href={returnUri} sx={{ marginTop: '30px' }} fullWidth>
          <FormattedMessage id="cancel_btn" />
        </Button>
      )}
    </Grid>
  );
};

ErrorPage.propTypes = {
  errorMsgId: PropTypes.string,
  padding: PropTypes.string,
};

ErrorPage.defaultProps = {
  errorMsgId: '',
  padding: '',
};
