/* eslint-disable indent */
/* eslint-disable operator-linebreak */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { setTrackingInformationByBeacon } from '../../fetch/requests';
import {
  EVENT_ACTIONS,
  EVENT_KEYS,
  EVENT_TYPES,
  STEPS,
  TrackingEvent,
  determineStep,
} from '../../utils/trackingEvents';

const updateEvents = ({ triggeredEvents, newEvent, updateState }) => {
  const combinedEvents = [...triggeredEvents, newEvent];
  const filteredEvents = Array.from(new Set(combinedEvents.map(JSON.stringify))).map(JSON.parse);
  updateState({ triggeredEvents: filteredEvents });
};

const handleButtonClick =
  ({ updateState, triggeredEvents, currentStep, eventType }) =>
  (event) => {
    const newEvent = new TrackingEvent({ eventType, currentStep });
    updateEvents({ triggeredEvents, newEvent, updateState });
    event.target.removeEventListener('click', handleButtonClick);
    event.target.click();
  };

const registerClickEvents = ({ applicationEvents, updateState, triggeredEvents, currentStep }) => {
  if (applicationEvents) {
    const applicationEventKeys = Object.keys(applicationEvents);

    if (applicationEventKeys?.length) {
      applicationEventKeys.forEach((key) => {
        const eventArrayStep = applicationEvents[key];
        eventArrayStep.forEach((event) => {
          const triggerItemName = event?.triggerItemName;
          const eventAction = event?.action;
          const eventType = event?.eventType;

          if (eventAction === EVENT_ACTIONS.CLICK && triggerItemName) {
            const triggerElement = document.getElementById(triggerItemName);

            if (triggerElement) {
              triggerElement.addEventListener(
                'click',
                handleButtonClick({ updateState, triggeredEvents, currentStep, eventType })
              );
            }
          }
        });
      });
    }
  }
};

const performOnPageLoadEventUpdate = ({
  eventArrayStep,
  currentStep,
  triggeredEvents,
  updateState,
}) => {
  if (eventArrayStep?.length) {
    eventArrayStep?.forEach((event) => {
      const isOnPageLoadEvent = event?.action === EVENT_ACTIONS.ON_PAGE_LOAD;

      if (isOnPageLoadEvent) {
        const eventType = event?.eventType;
        const newEvent = new TrackingEvent({ eventType, currentStep });
        updateEvents({ triggeredEvents, newEvent, updateState });
      }
    });
  }
};

const registerOnPageLoadEvents = ({
  applicationEvents,
  updateState,
  triggeredEvents,
  currentStep,
}) => {
  if (applicationEvents) {
    const isNumericStep = !Number.isNaN(Number(currentStep));
    const isLastStep = currentStep === STEPS.LAST_STEP;
    const isStepAuthorized = currentStep === STEPS.STEP_AUTHORIZED;

    if (isNumericStep) {
      const eventArrayStep = applicationEvents[`step${currentStep}`];
      performOnPageLoadEventUpdate({ eventArrayStep, currentStep, triggeredEvents, updateState });
    }

    if (isLastStep) {
      const applicationEventKeys = Object.keys(applicationEvents);
      const filteredApplicationEventKeys = applicationEventKeys.filter((key) => /.+\d$/.test(key));
      const lastStepKey = filteredApplicationEventKeys[filteredApplicationEventKeys.length - 1];
      const eventArrayStep = applicationEvents[lastStepKey];
      performOnPageLoadEventUpdate({ eventArrayStep, currentStep, triggeredEvents, updateState });
    }

    if (isStepAuthorized) {
      if (EVENT_KEYS.STEP_OBTAIN_TOKEN in applicationEvents) {
        const eventArrayStep = applicationEvents.stepObtainToken;
        performOnPageLoadEventUpdate({ eventArrayStep, currentStep, triggeredEvents, updateState });
      }
    }
  }
};

const handleRedirect =
  ({ updateState, triggeredEvents, currentStep, eventType }) =>
  (event) => {
    const newEvent = new TrackingEvent({ eventType, currentStep });
    updateEvents({ triggeredEvents, newEvent, updateState });
    event.target.removeEventListener('beforeunload', handleRedirect);
  };

const registerRedirectEvents = ({
  applicationEvents,
  updateState,
  triggeredEvents,
  currentStep,
}) => {
  const isStepAuthorized = currentStep === STEPS.STEP_AUTHORIZED;

  if (applicationEvents && isStepAuthorized) {
    if (EVENT_KEYS.STEP_OBTAIN_TOKEN in applicationEvents) {
      const eventArrayStep = applicationEvents.stepObtainToken;

      if (eventArrayStep?.length) {
        eventArrayStep?.forEach((event) => {
          const isOnUserRedirect = event?.action === EVENT_ACTIONS.ON_USER_REDIRECT;
          const eventType = event?.eventType;

          if (isOnUserRedirect) {
            window.addEventListener(
              'click',
              handleRedirect({ updateState, triggeredEvents, currentStep, eventType })
            );
          }
        });
      }
    }
  }
};

const performEventHandlerForSendBeacon = ({
  currentStep = 'STEP',
  eventType = 'EVENT_TYPE',
  clientId = 'CLIENT_ID',
  trackingId = 'TRACKING_ID',
}) => {
  const requestBody = {
    client_id: clientId,
    tracking_id: trackingId,
    event_type: eventType,
    event_payload: {
      STEP: currentStep,
    },
  };
  setTrackingInformationByBeacon({ requestBody });
};

const registerWindowClosedEvents = ({ currentStep, clientId, trackingId }) => {
  window.addEventListener(
    'beforeunload',
    (event) => {
      const isConnect = currentStep === 'connect';
      if (isConnect) {
        return;
      }

      if (window?.location?.pathname?.includes(currentStep)) {
        const isLastStep = currentStep === 'last-step';
        const isStepAuthorized = currentStep === 'step-authorized';

        if (isLastStep || isStepAuthorized) {
          performEventHandlerForSendBeacon({
            currentStep,
            eventType: 'USER_REDIRECT',
            clientId,
            trackingId,
          });
          return;
        }
        const confirmationMessage = 'Are you sure you want to leave?';
        // eslint-disable-next-line no-param-reassign
        event.returnValue = confirmationMessage;
        performEventHandlerForSendBeacon({
          currentStep,
          eventType: EVENT_TYPES.WINDOW_CLOSED,
          clientId,
          trackingId,
        });
        return confirmationMessage;
      }
    },
    false
  );
};

const useTrackingEvents = ({ currentApp, triggeredEvents, clientId, trackingId, updateState }) => {
  const { pathname } = useLocation();
  const applicationEvents = currentApp?.applicationEvents;
  const currentStep = determineStep(pathname);

  useEffect(() => {
    registerClickEvents({ applicationEvents, updateState, triggeredEvents, currentStep });

    registerOnPageLoadEvents({
      applicationEvents,
      updateState,
      triggeredEvents,
      currentStep,
    });

    registerRedirectEvents({
      applicationEvents,
      updateState,
      triggeredEvents,
      currentStep,
    });

    if (clientId && trackingId) {
      registerWindowClosedEvents({ currentStep, clientId, trackingId });
    }
  }, [pathname, updateState, trackingId, clientId]);
};

export default useTrackingEvents;
