import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import MuiCheckbox from '@mui/material/Checkbox';

const Checkbox = ({ field, formValues, onChange, dataTestid }) => {
  const intl = useIntl();

  return (
    <Grid container item key={field.index} alignItems="center" marginY="20px">
      <FormControl>
        <FormControlLabel
          control={
            <MuiCheckbox
              name={field.name}
              checked={formValues[field.name]}
              onChange={onChange}
              data-testid={dataTestid}
            />
          }
          label={intl.formatMessage({ id: field.displayName })}
        />
      </FormControl>
    </Grid>
  );
};

Checkbox.defaultProps = {
  dataTestid: 'data_testid',
};

Checkbox.propTypes = {
  field: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
};

export default Checkbox;
