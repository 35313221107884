import React from 'react';

const Norsk = () => (
  <div style={{ transform: 'scale(0.5)' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="48px"
      height="48px"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          style={{ opacity: 0.965 }}
          fill="#ff3c00"
          d="M 22.5,3.5 C 33.7819,3.29931 40.7819,8.63265 43.5,19.5C 36.5,19.5 29.5,19.5 22.5,19.5C 22.5,14.1667 22.5,8.83333 22.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#4350b2"
          d="M 20.5,3.5 C 20.5,9.5 20.5,15.5 20.5,21.5C 28.1667,21.5 35.8333,21.5 43.5,21.5C 43.5,22.8333 43.5,24.1667 43.5,25.5C 35.8333,25.5 28.1667,25.5 20.5,25.5C 20.5,31.5 20.5,37.5 20.5,43.5C 18.901,43.7678 17.5676,43.4345 16.5,42.5C 16.5,36.8333 16.5,31.1667 16.5,25.5C 12.1667,25.5 7.83333,25.5 3.5,25.5C 3.5,24.1667 3.5,22.8333 3.5,21.5C 7.83333,21.5 12.1667,21.5 16.5,21.5C 16.5,15.8333 16.5,10.1667 16.5,4.5C 17.5676,3.56552 18.901,3.23219 20.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#ecedee"
          d="M 20.5,3.5 C 21.1667,3.5 21.8333,3.5 22.5,3.5C 22.5,8.83333 22.5,14.1667 22.5,19.5C 29.5,19.5 36.5,19.5 43.5,19.5C 43.5,20.1667 43.5,20.8333 43.5,21.5C 35.8333,21.5 28.1667,21.5 20.5,21.5C 20.5,15.5 20.5,9.5 20.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.938 }}
          fill="#ff3c00"
          d="M 14.5,5.5 C 14.5,10.1667 14.5,14.8333 14.5,19.5C 10.8333,19.5 7.16667,19.5 3.5,19.5C 4.79969,12.8673 8.46635,8.20058 14.5,5.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#ecebeb"
          d="M 16.5,4.5 C 16.5,10.1667 16.5,15.8333 16.5,21.5C 12.1667,21.5 7.83333,21.5 3.5,21.5C 3.5,20.8333 3.5,20.1667 3.5,19.5C 7.16667,19.5 10.8333,19.5 14.5,19.5C 14.5,14.8333 14.5,10.1667 14.5,5.5C 14.8417,4.66175 15.5084,4.32842 16.5,4.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#edeaeb"
          d="M 3.5,25.5 C 7.83333,25.5 12.1667,25.5 16.5,25.5C 16.5,31.1667 16.5,36.8333 16.5,42.5C 15.5084,42.6716 14.8417,42.3382 14.5,41.5C 14.5,36.8333 14.5,32.1667 14.5,27.5C 10.8333,27.5 7.16667,27.5 3.5,27.5C 3.5,26.8333 3.5,26.1667 3.5,25.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 1 }}
          fill="#ecedef"
          d="M 43.5,25.5 C 43.5,26.1667 43.5,26.8333 43.5,27.5C 36.5,27.5 29.5,27.5 22.5,27.5C 22.5,32.8333 22.5,38.1667 22.5,43.5C 21.8333,43.5 21.1667,43.5 20.5,43.5C 20.5,37.5 20.5,31.5 20.5,25.5C 28.1667,25.5 35.8333,25.5 43.5,25.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.945 }}
          fill="#ff3c00"
          d="M 3.5,27.5 C 7.16667,27.5 10.8333,27.5 14.5,27.5C 14.5,32.1667 14.5,36.8333 14.5,41.5C 8.46635,38.7994 4.79969,34.1327 3.5,27.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.968 }}
          fill="#ff3c00"
          d="M 43.5,27.5 C 40.7819,38.3674 33.7819,43.7007 22.5,43.5C 22.5,38.1667 22.5,32.8333 22.5,27.5C 29.5,27.5 36.5,27.5 43.5,27.5 Z"
        />
      </g>
    </svg>
  </div>
);

export default Norsk;
