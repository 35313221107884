import React, { useEffect, useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import Grid from '@mui/material/Grid';
import { FinishAuthLoader, SuccessConnection } from '../components/FinishAuthorize';
import { setConnectionData } from '../../fetch/requests';
import { ConnectorContext } from '../../context';

export const FinishAuthorize = () => {
  const [isAnimationFinished, setIsAnimationFinished] = useState(false);

  const { updateState } = useContext(ConnectorContext);
  const { search } = useLocation();
  const params = queryString.parse(search);

  useEffect(() => {
    const { state, code, ...restParams } = params;
    const requestBody = { state, code, ...restParams };

    setConnectionData({ updateState, requestBody });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAnimationFinished = () => {
    setIsAnimationFinished(true);
  };

  return (
    <Grid container item justifyContent="center" direction="column" margin="0 auto">
      {!isAnimationFinished ? (
        <FinishAuthLoader
          isAnimationFinished={isAnimationFinished}
          setAnimationFinished={setAnimationFinished}
        />
      ) : (
        <SuccessConnection />
      )}
    </Grid>
  );
};
