import React from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const FinishAuthTitleContent = () => (
  <Grid paddingBottom="24px" minHeight="50px" item xs>
    <Typography
      gutterBottom
      sx={{
        textAlign: 'center',
        color: '#484848',
        fontSize: '18px',
        fontWeight: 700,
        lineHeight: '24.51px',
      }}
    >
      <FormattedMessage id="successful_connection_title" />
    </Typography>
  </Grid>
);
