const snakeToCamel = (str) => str.replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());

export const spacesToUnderscore = (str) => str.replaceAll(' ', '_');

const isArray = (a) => Array.isArray(a);

const isObject = (o) => o === Object(o) && !isArray(o) && typeof o !== 'function';

export const keysToCamel = (obj) => {
  if (isObject(obj)) {
    const updatedObj = {};

    Object.keys(obj).forEach((key) => {
      updatedObj[snakeToCamel(key)] = keysToCamel(obj[key]);
    });

    return updatedObj;
  }

  if (isArray(obj)) {
    return obj.map((item) => keysToCamel(item));
  }

  return obj;
};

export const reverseString = (str) => str && str.split('').reverse().join('');

export const omit = (keys, obj) =>
  Object.fromEntries(Object.entries(obj).filter(([k]) => !keys.includes(k)));
