import React from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import './dateCalendar.css';

const DateCalendar = ({ field, formValues, onChange, dataTestid }) => {
  const dateOneDayBefore = new Date(new Date().getTime() - 24 * 60 * 60 * 1000);

  return (
    <Grid
      container
      item
      key={field.index}
      alignItems="center"
      justifyContent="center"
      marginY="20px"
      data-testid={dataTestid}
    >
      <Calendar
        value={formValues[field.name] || field.defaultValue}
        onChange={onChange}
        tileDisabled={({ date }) => new Date(date) < dateOneDayBefore}
      />
    </Grid>
  );
};

DateCalendar.defaultProps = {
  dataTestid: 'data_testid',
};

DateCalendar.propTypes = {
  field: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
};

export default DateCalendar;
