import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Alert from '@mui/material/Alert';

export const InfoAlert = ({ infoMsgId }) => (
  <Grid container item justifyContent="center" padding="60px 0">
    <Alert severity="info">
      <FormattedMessage id={infoMsgId || 'no_data_msg'} />
    </Alert>
  </Grid>
);

InfoAlert.propTypes = {
  infoMsgId: PropTypes.string,
};

InfoAlert.defaultProps = {
  infoMsgId: '',
};
