const UNIX_TIME_ZERO = 'Thu, 01 Jan 1970 00:00:01 GMT';

export const setCookie = (key, value, expirationDate) => {
  const cookieData = `${key}=${value}`;
  document.cookie = expirationDate ? `${cookieData}; expires=${expirationDate}` : cookieData;
};

export const getCookie = (key) =>
  document.cookie
    ?.split('; ')
    ?.filter((value) => value.includes(key))[0]
    ?.split('=')[1];

export const deleteCookie = (key) => {
  const cookie = getCookie(key);

  if (cookie) {
    document.cookie = `${key}=;expires=${UNIX_TIME_ZERO}`;
  }
};

export const deleteAllCookies = () => {
  const cookies = document.cookie.split('; ');

  for (let i = 0; i < cookies.length; i += 1) {
    const cookie = cookies[i];
    const equalSignPosition = cookie.indexOf('=');
    const cookieName = equalSignPosition > -1 ? cookie.substr(0, equalSignPosition) : cookie;
    document.cookie = `${cookieName}=;expires=${UNIX_TIME_ZERO}`;
  }
};
