import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

const Input = ({ field, formValues, onChange, dataTestid }) => {
  const intl = useIntl();

  return (
    <Grid container item key={field.index} alignItems="center" marginY="20px">
      <TextField
        fullWidth
        size="small"
        required={field.required}
        id={field.name}
        label={intl.formatMessage({ id: field.displayName })}
        name={field.name}
        placeholder={field.placeholder}
        value={formValues[field.name]}
        onChange={onChange}
        data-testid={dataTestid}
        inputProps={{ 'data-testid': dataTestid }}
      />
    </Grid>
  );
};

Input.defaultProps = {
  dataTestid: 'data_testid',
};

Input.propTypes = {
  field: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
};

export default Input;
