/* eslint-disable react/destructuring-assignment */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { ConnectorContext } from '../../context';
import LanguageSelector from './LanguageSelector';

export const Header = ({ selectedLanguage, handleLanguageChange }) => {
  const {
    state: { currentApp, clientLogoUrl },
  } = useContext(ConnectorContext);
  const { search } = useLocation();
  const navigate = useNavigate();
  const { id } = useParams();
  const isApplicationId = search.includes('application_id');

  let shouldDisplayBackButton = false;
  if (currentApp?.steps) {
    const stepsLength = Object.keys(currentApp.steps).length;
    shouldDisplayBackButton = id >= 1 && id < stepsLength;
  }

  const goBack = () => navigate(-1);

  return (
    <Grid
      container
      item
      justifyContent="center"
      direction="column"
      margin="60px auto"
      position="relative"
    >
      <LanguageSelector
        selectedLanguage={selectedLanguage}
        handleLanguageChange={handleLanguageChange}
      />

      {shouldDisplayBackButton && !isApplicationId ? (
        <Grid item position="absolute" bottom="65px">
          <Button data-testid="go_back_btn" onClick={goBack} startIcon={<ArrowBackIosIcon />}>
            <FormattedMessage id="back" />
          </Button>
        </Grid>
      ) : null}

      {clientLogoUrl ? (
        <Grid
          item
          container
          alignItems="center"
          justifyContent="center"
          margin="0 auto"
          height="50px"
          borderRadius="4px"
        >
          <CardMedia src={clientLogoUrl} component="img" height="50px" data-testid="client_logo" />
        </Grid>
      ) : null}
    </Grid>
  );
};

Header.propTypes = {
  selectedLanguage: PropTypes.string.isRequired,
  handleLanguageChange: PropTypes.func.isRequired,
};
