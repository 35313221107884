import React, { useState, useContext, useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { StartAuthLoader, StartAuthTitleContent } from '../components';
import { ConnectorContext } from '../../context';
import { getAuthorizeUrl } from '../../fetch/requests';
import useTrackingEvents from '../hooks/useTrackingEvents';
import useSendTrackingEvents from '../hooks/useSendTrackingEvents';

const TIMEOUT_DELAY = 3000;

export const StartAuthorize = () => {
  const [isStartedConnection, setIsStartedConnection] = useState(false);
  const [isStartSendingEvents, setIsStartSendingEvents] = useState(false);
  const [isEventsSent, setIsEventsSent] = useState(false);

  const {
    state: {
      currentApp: currentApplication,
      currentApp: { steps, id },
      clientId,
      state,
      preStartConnection,
      applicationData,
      triggeredEvents,
      trackingId,
    },
    updateState,
  } = useContext(ConnectorContext);

  const stepsLength = Object.keys(steps).length;

  useEffect(() => {
    if (preStartConnection.isReadyToConnect && isEventsSent) {
      setTimeout(() => {
        window.location.href = preStartConnection.redirectUrl;
      }, TIMEOUT_DELAY);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [preStartConnection.isReadyToConnect, isEventsSent]);

  useEffect(() => {
    if (preStartConnection.isReadyToConnect) {
      setIsStartSendingEvents(true);
    }
  }, [preStartConnection.isReadyToConnect]);

  useTrackingEvents({
    currentApp: currentApplication,
    triggeredEvents,
    clientId,
    trackingId: preStartConnection?.trackingId || trackingId,
    updateState,
  });

  useEffect(() => {
    if (triggeredEvents?.length && preStartConnection?.isReadyToConnect) {
      if (preStartConnection?.trackingId) {
        updateState({ trackingId: preStartConnection?.trackingId });
        sessionStorage.setItem('trackingId', preStartConnection?.trackingId);
      }
      setIsStartSendingEvents(true);
    }
  }, [triggeredEvents, preStartConnection.isReadyToConnect]);

  useSendTrackingEvents({
    isStartSendingEvents,
    triggeredEvents,
    trackingId: preStartConnection?.trackingId || trackingId,
    clientId,
    setIsEventsSent,
  });

  const startConnectionBtn = steps[stepsLength][0];

  const onStartConnection = () => {
    setIsStartedConnection(!isStartedConnection);
    const requestBody = {
      application_id: id,
      client_id: clientId,
      application_data: applicationData || {},
      state,
    };
    getAuthorizeUrl({ updateState, requestBody });
  };

  const renderBtn = () => (
    <Button
      variant="contained"
      sx={{ marginTop: '10px', color: '#fff' }}
      color="blackBtn"
      onClick={onStartConnection}
      disabled={isStartedConnection}
      fullWidth
      id={startConnectionBtn?.name || 'redirect_btn'}
      data-testid={startConnectionBtn?.name || 'redirect_btn'}
    >
      <FormattedMessage id={startConnectionBtn.displayName} />
    </Button>
  );

  return (
    <Grid container item justifyContent="center" direction="column" margin="0 auto">
      <StartAuthTitleContent isStartedConnection={isStartedConnection} />

      <StartAuthLoader isStartedConnection={isStartedConnection} />

      <Grid>{renderBtn()}</Grid>
    </Grid>
  );
};
