import React from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import { spacesToUnderscore } from '../../../utils/converters';

const Radiobutton = ({ field, formValues, onChange, dataTestid }) => {
  const intl = useIntl();

  return (
    <Grid container item key={field.index} alignItems="center" marginY="20px">
      <FormControl>
        <FormLabel>{intl.formatMessage({ id: field.displayName })}</FormLabel>
        <RadioGroup
          data-testid={dataTestid}
          name={field.name}
          value={formValues[field.name]}
          onChange={onChange}
        >
          {field.options.map((option) => (
            <FormControlLabel
              control={
                <Radio
                  inputProps={{
                    'data-testid': `radio_button_${spacesToUnderscore(option.label)}`,
                  }}
                />
              }
              key={option.label}
              value={option.value}
              label={option.label}
            />
          ))}
        </RadioGroup>
      </FormControl>
    </Grid>
  );
};

Radiobutton.defaultProps = {
  dataTestid: 'data_testid',
};

Radiobutton.propTypes = {
  field: PropTypes.object.isRequired,
  formValues: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  dataTestid: PropTypes.string,
};

export default Radiobutton;
