import React from 'react';

const Svenska = () => (
  <div style={{ transform: 'scale(0.5)' }}>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      version="1.1"
      width="48px"
      height="48px"
      style={{
        shapeRendering: 'geometricPrecision',
        textRendering: 'geometricPrecision',
        imageRendering: 'optimizeQuality',
        fillRule: 'evenodd',
        clipRule: 'evenodd',
      }}
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <g>
        <path
          style={{ opacity: 0.994 }}
          fill="#2095f2"
          d="M 21.5,3.5 C 21.5,9.16667 21.5,14.8333 21.5,20.5C 28.8333,20.5 36.1667,20.5 43.5,20.5C 43.5,22.5 43.5,24.5 43.5,26.5C 36.1667,26.5 28.8333,26.5 21.5,26.5C 21.5,32.1667 21.5,37.8333 21.5,43.5C 19.4352,43.4828 17.4352,43.1495 15.5,42.5C 15.5,37.1667 15.5,31.8333 15.5,26.5C 11.5,26.5 7.5,26.5 3.5,26.5C 3.5,24.5 3.5,22.5 3.5,20.5C 7.5,20.5 11.5,20.5 15.5,20.5C 15.5,15.1667 15.5,9.83333 15.5,4.5C 17.4352,3.85048 19.4352,3.51715 21.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.973 }}
          fill="#eceff1"
          d="M 21.5,3.5 C 33.7525,2.94548 41.0858,8.61215 43.5,20.5C 36.1667,20.5 28.8333,20.5 21.5,20.5C 21.5,14.8333 21.5,9.16667 21.5,3.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.955 }}
          fill="#eceff1"
          d="M 15.5,4.5 C 15.5,9.83333 15.5,15.1667 15.5,20.5C 11.5,20.5 7.5,20.5 3.5,20.5C 4.60633,12.9376 8.60633,7.60423 15.5,4.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.954 }}
          fill="#eceff1"
          d="M 3.5,26.5 C 7.5,26.5 11.5,26.5 15.5,26.5C 15.5,31.8333 15.5,37.1667 15.5,42.5C 8.60633,39.3958 4.60633,34.0624 3.5,26.5 Z"
        />
      </g>
      <g>
        <path
          style={{ opacity: 0.973 }}
          fill="#eceff1"
          d="M 43.5,26.5 C 41.0653,38.3948 33.732,44.0615 21.5,43.5C 21.5,37.8333 21.5,32.1667 21.5,26.5C 28.8333,26.5 36.1667,26.5 43.5,26.5 Z"
        />
      </g>
    </svg>
  </div>
);

export default Svenska;
